import React from "react";

function AvoidBankruptcy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="74"
            height="75"
            fill="none"
            viewBox="0 0 74 75"
        >
            <path
                fill="#000"
                d="M72.558 73.038h-4.361V33.01a5.926 5.926 0 00-.595-2.579l-1.41-2.896a.98.98 0 00-.982-.548l-3.875.394-2.098-7.868a.98.98 0 00-1.074-.72l-6.862.88-2.662-3.432A12.471 12.471 0 0026.134 5.93H6.284a.982.982 0 00-.982.983v66.123H.942a.982.982 0 000 1.963h71.615a.982.982 0 000-1.963zM36.75 1.964a10.508 10.508 0 11-10.511 10.51 10.52 10.52 0 0110.51-10.51zM28.97 73.038v-9.21h15.557v9.21H28.97zm17.52 0V62.845a.98.98 0 00-.98-.98H27.99a.983.983 0 00-.983.98v10.192H7.265V7.896h17.882A12.473 12.473 0 0047.76 18.316l1.722 2.22a.924.924 0 000 .13v6.711c0 .26.103.509.286.694l3.356 3.355-4.358 4.357a.981.981 0 001.387 1.39l4.359-4.358 3.33 3.33a.982.982 0 001.387-1.386l-4.017-4.033-3.762-3.762v-5.33l6.12-.785 2.084 7.845a.981.981 0 001.049.723l4.026-.407 1.11 2.28h-.001c.26.536.395 1.125.396 1.72v5.644h-4.097a.982.982 0 000 1.963h4.097v32.421H46.491z"
            ></path>
            <path
                fill="#000"
                d="M23.04 28.236h-7.83a.983.983 0 00-.982.98v7.83c0 .542.44.982.983.982h7.828a.982.982 0 00.98-.983v-7.828a.98.98 0 00-.98-.98zm-.983 7.829h-5.865v-5.866h5.865v5.866zM40.665 28.236h-7.829a.982.982 0 00-.982.98v7.83c0 .542.44.982.982.982h7.829a.982.982 0 00.98-.983v-7.828a.98.98 0 00-.98-.98zm-.983 7.829h-5.865v-5.866h5.865v5.866zM23.04 45.4h-7.83a.982.982 0 00-.982.982v7.829c.002.542.441.98.983.98h7.828a.98.98 0 00.98-.98v-7.829a.982.982 0 00-.98-.982zm-.983 7.828h-5.865v-5.865h5.865v5.865zM40.665 45.4h-7.829a.982.982 0 00-.982.982v7.829c.001.542.44.98.982.98h7.829a.98.98 0 00.98-.98v-7.829a.982.982 0 00-.98-.982zm-.983 7.828h-5.865v-5.865h5.865v5.865zM58.288 45.4h-7.82a.982.982 0 00-.98.982v7.829c0 .541.439.98.98.98h7.829a.98.98 0 00.98-.98v-7.829a.983.983 0 00-.989-.982zm-.982 7.828H51.44v-5.865h5.865v5.865zM51.696 8.636a.983.983 0 001.106.836.978.978 0 00.847-1.106l-.424-3.068a.98.98 0 10-1.944.268l.415 3.07zM59.677 14.636a.978.978 0 001.345-.34l1.958-3.278a.98.98 0 10-1.685-1.006l-1.958 3.268a.98.98 0 00.34 1.356zM67.216 22.493c.313 0 .43 0 3.467-1.834a.982.982 0 10-1.017-1.68c-1.1.665-2.428 1.443-2.745 1.595a.981.981 0 00.293 1.919h.002zM36.818 17.38h-.042c-1.817-.046-2.173-1.04-2.202-1.13a.983.983 0 00-1.907.47c.023.094.544 2.058 3.1 2.526v1.309a.98.98 0 001.962 0v-1.326c1.484-.374 2.76-1.62 3.06-3.12.151-.752.33-3.326-3.688-4.874-1.771-.678-2.671-1.65-2.47-2.656.179-.893 1.164-1.693 2.09-1.658 1.801.05 2.167 1.016 2.203 1.154a.983.983 0 001.9-.494c-.024-.093-.542-2.056-3.1-2.525V3.745a.98.98 0 00-1.96 0V5.07C34.278 5.445 33 6.69 32.7 8.192c-.15.75-.328 3.325 3.689 4.873 1.771.678 2.672 1.651 2.47 2.657-.169.88-1.138 1.659-2.042 1.659z"
            ></path>
        </svg>
    );
}

export default AvoidBankruptcy;
