import React, { ReactElement } from "react";

import styles from "./style.module.scss";

export default function HowDebtWorks(): ReactElement {
    return (
        <section className={`${styles["mainSection"]}   `}>
            <div className="lg:py-24 py-14">
                <h2>How Debt Relief Works</h2>
                <p>
                    In a number of situations, debt relief may be the only
                    course of action in order to avoid bankruptcy. If a massive
                    debt load makes it difficult to service borrowings, for
                    example, creditors may be amenable to restructuring the debt
                    and providing relief rather than risk the borrower
                    defaulting on its obligations and increasing overall credit
                    risk. Refinancing a mortgage to a lower interest rate is one
                    straightforward example of debt relief.
                </p>
                <p>
                    Another common form of debt relief involves debt
                    consolidation, or the combining of several higher-interest
                    loans into a single lower-interest loan. There are several
                    ways consumers can lump debts into a single payment. One
                    method is to consolidate all their credit card payments into
                    one new credit card, which can be a good idea if the card
                    charges little or no interest during an introductory period.
                    They may also utilize an existing credit card's balance
                    transfer feature (especially if it offers a special
                    promotion on the transaction).
                </p>
                <p>
                    Home equity loans and home equity lines of credit (HELOC)
                    are another form of consolidation sought by some people.
                    Usually, the interest for this type of loan is deductible
                    for taxpayers who itemize their deductions. There also are
                    several options available from the federal government for
                    people who want to consolidate their student loans.
                </p>
            </div>
        </section>
    );
}
