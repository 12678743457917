import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import SharedButton from "../sharedButton";
import DebtSettlementFees from "./icons/debtSettlementFees";
import DebtSettlementImpact from "./icons/debtSettlementImpact";
import HoldingFunds from "./icons/holdingFunds";
import DebtSettlementTax from "./icons/debtSettlementTax";
const list = [
    { icon: <DebtSettlementFees />, title: "Debt Settlement Fees" },
    {
        icon: <DebtSettlementImpact />,
        title: "Debt Settlement Impact on Credit Score",
    },
    {
        icon: <HoldingFunds />,
        title: "Holding Funds",
    },
    { icon: <DebtSettlementTax />, title: "Debt Settlement Tax Implications" },
];
export default function DisadvantagesDebtSettlement(): ReactElement {
    return (
        <section className={` ${styles["wrapperBackground"]}   `}>
            <div className="lg:pt-16 pt-10 lg:pb-52 pb-28">
                <FeatureList
                    featureList={list}
                    mainTitle="Benefits of Debt Settlement"
                    classes={{
                        mainTitleClasses: ` font-bold text-2xl text-center   lg:text-3xl  `,
                        innerSectionClasses: `flex flex-wrap justify-center mt-[70px]
                           gap-[100px]
                        `,
                        oneBoxClasses: `text-center  
                                 mx-auto lg:mx-0  `,
                        iconClasses: "children:mx-auto",
                        titleClasses: `  lg:text-lg max-w-[215px] mt-[40px]`,

                        mainSectionClasses: " ",
                    }}
                    colors={{
                        mainTitleColor: "#204B6E",
                    }}
                />
                <div className="text-center  mt-24 ">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
