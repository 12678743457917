import { ReactElement } from "react";
import styles from "./style.module.scss";

export default function SharedButton(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={() => scrollToTop()}
            className={`bg-[#e17d5b] text-white py-4 sm:px-11 px-4 sm:text-lg font-semibold rounded-lg ${styles["animation"]}`}
        >
            Get My Settlement Today
        </button>
    );
}
