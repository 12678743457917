import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import MainImage from "./MainImage";

export default function WhatDebtRelief(): ReactElement {
    return (
        <section className={`${styles["wrapper"]}  `}>
            <div
                className={`lg:pt-28 pt-14  flex flex-col lg:flex-row gap-11  `}
            >
                <div className={`max-w-[621px] mx-auto lg:mx-0 `}>
                    <h2>What Is Debt Relief?</h2>
                    <span></span>
                    <p>
                        Debt relief is the reorganization of debt in any shape
                        or form so as to provide the indebted party with a
                        measure of respite, either fully or partially. Debt
                        relief can take a number of forms: reducing the
                        outstanding principal amount (again, either partially or
                        fully), lowering the interest rate on loans due, or
                        extending the term of the loan, among others.
                    </p>
                    <p>
                        Creditors may only be willing to consider debt-relief
                        measures when the repercussions of debt default by the
                        indebted party or parties are perceived as being so
                        severe that debt mitigation is a better alternative.
                        Debt relief may be extended to any highly indebted
                        party, from individuals and small businesses to large
                        companies, municipalities, and even sovereign nations.
                    </p>
                </div>
                <div
                    className={`w-[300px] h-[200px] lg:w-[525px] lg:h-[363px] lg:min-w-[525px] relative  mx-auto lg:mx-0  lg:mt-20`}
                >
                    <MainImage />
                </div>
            </div>
        </section>
    );
}
