import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import SharedButton from "../sharedButton";
import GetReliefDebt from "./icons/getReliefDebt";
import AvoidBankruptcy from "./icons/avoidBankruptcy";
import YourDebtOff from "./icons/yourDebtOff";
import AvoidDebt from "./icons/avoidDebt";
const list = [
    { icon: <GetReliefDebt />, title: "Get Relief from Unbearable Debt " },
    { icon: <AvoidBankruptcy />, title: "Avoid Bankruptcy" },
    {
        icon: <YourDebtOff />,
        title: "Your Debt Won't Be Sent to Collections or Charged Off ",
    },
    { icon: <AvoidDebt />, title: "Avoid Being Sued for Your Debt" },
];
export default function DebtSettlement(): ReactElement {
    return (
        <section className={` ${styles["wrapperBackground"]}   `}>
            <div className="pt-16">
                <FeatureList
                    featureList={list}
                    mainTitle="Benefits of Debt Settlement"
                    classes={{
                        mainTitleClasses: ` font-bold text-2xl text-center   lg:text-3xl  `,
                        innerSectionClasses: `flex flex-wrap justify-center mt-[71px]
                           gap-[19px]
                        `,
                        oneBoxClasses: `text-center px-[22px] py-[40px] ${styles["shadowStyle"]} w-[285px] h-[234px]
                                 mx-auto lg:mx-0  `,
                        iconClasses: "children:mx-auto",
                        titleClasses: `  lg:text-lg mt-[30px]`,

                        mainSectionClasses: " ",
                    }}
                    colors={{
                        mainTitleColor: "#204B6E",
                    }}
                />
                <div className="text-center  mt-24 ">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
