import React from "react";

function DebtSettlementTax() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="152"
            height="152"
            fill="none"
            viewBox="0 0 152 152"
        >
            <circle cx="76" cy="76" r="76" fill="#D9EDFD"></circle>
            <path
                fill="#000"
                d="M50.378 38h51.244c.761 0 1.378.623 1.378 1.391v74.218c0 .768-.617 1.391-1.378 1.391H50.378c-.76 0-1.378-.623-1.378-1.391V39.391c0-.768.617-1.391 1.378-1.391zm26.968 21.063c2.812.369 4.74 1.828 5.674 3.616a5.633 5.633 0 01.578 3.59c-.21 1.203-.823 2.359-1.859 3.296-1.028.93-2.489 1.638-4.393 1.924v2.013c0 .768-.618 1.39-1.378 1.39-.761 0-1.378-.622-1.378-1.39v-1.896c-1.317-.068-2.78-.305-4.393-.745a1.392 1.392 0 01-.975-1.7 1.376 1.376 0 011.684-.985c1.375.376 2.602.585 3.683.658v-7.19c-2.776-.384-4.68-1.834-5.61-3.607a5.633 5.633 0 01-.578-3.59c.21-1.204.824-2.36 1.86-3.297 1.016-.92 2.455-1.62 4.328-1.914v-2.024c0-.768.618-1.391 1.379-1.391.76 0 1.378.623 1.378 1.391v1.894c1.332.064 2.817.3 4.457.748.734.197 1.17.96.975 1.7a1.376 1.376 0 01-1.685.985c-1.403-.384-2.65-.593-3.748-.662v7.185h.001zm3.241 4.909c-.515-.985-1.606-1.804-3.24-2.105v6.823c1.114-.223 1.963-.648 2.563-1.191.546-.495.869-1.094.975-1.709a2.858 2.858 0 00-.299-1.819h.001zm-5.998-5.136v-6.797c-1.082.227-1.91.645-2.498 1.178-.546.495-.87 1.094-.976 1.708a2.858 2.858 0 00.299 1.82c.508.971 1.578 1.781 3.175 2.091zM58.645 86.784c-.761 0-1.378-.624-1.378-1.392 0-.768.617-1.391 1.378-1.391h34.712c.76 0 1.378.623 1.378 1.391s-.618 1.392-1.378 1.392H58.644zm0 8.895c-.761 0-1.378-.623-1.378-1.39 0-.769.617-1.392 1.378-1.392h34.712c.76 0 1.378.623 1.378 1.391s-.618 1.392-1.378 1.392H58.644zm0 8.898c-.761 0-1.378-.623-1.378-1.392 0-.768.617-1.391 1.378-1.391h34.712c.76 0 1.378.623 1.378 1.391 0 .769-.618 1.392-1.378 1.392H58.644zm41.6-63.796H51.757v71.435h48.487V40.781z"
            ></path>
        </svg>
    );
}

export default DebtSettlementTax;
