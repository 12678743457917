import React from "react";

function DebtSettlementImpact() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="152"
            height="152"
            fill="none"
            viewBox="0 0 152 152"
        >
            <circle cx="76" cy="76" r="76" fill="#D9EDFD"></circle>
            <path
                fill="#000"
                d="M76.32 111.765c19.576 0 35.444-15.87 35.444-35.445 0-19.576-15.868-35.445-35.445-35.445-19.576 0-35.445 15.868-35.445 35.445 0 19.576 15.87 35.445 35.445 35.445zm0 2.874c-21.164 0-38.32-17.156-38.32-38.32C38 55.156 55.156 38 76.32 38c21.163 0 38.319 17.156 38.319 38.32 0 21.163-17.156 38.319-38.32 38.319z"
            ></path>
            <path
                fill="#000"
                d="M105.538 76.32a1.436 1.436 0 11-2.874 0c0-14.55-11.794-26.345-26.344-26.345S49.975 61.77 49.975 76.32a1.437 1.437 0 01-2.874 0c0-16.137 13.082-29.22 29.219-29.22s29.219 13.082 29.219 29.22h-.001z"
            ></path>
            <path
                fill="#000"
                d="M76.798 82.547a5.748 5.748 0 110-11.496 5.748 5.748 0 010 11.496zm0-2.874a2.874 2.874 0 10.001-5.747 2.874 2.874 0 000 5.747z"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M76.798 56.202c.794 0 1.437.794 1.437 1.436V71.05c0 .794-.644 1.437-1.437 1.437-.794 0-1.437-.794-1.437-1.437V57.638c0-.793.644-1.436 1.437-1.436z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#000"
                d="M102.6 81.034a1.438 1.438 0 012.784.713c-.886 3.459-2.331 6.617-4.33 9.465-2.006 2.857-3.449 4.576-4.495 5.273a1.437 1.437 0 11-1.593-2.392c.64-.427 1.942-1.977 3.736-4.532 1.799-2.563 3.098-5.403 3.898-8.527z"
            ></path>
            <path
                fill="#000"
                fillRule="evenodd"
                d="M93.563 98.832a1.438 1.438 0 11-2.875 0 1.438 1.438 0 012.875 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default DebtSettlementImpact;
