import React from "react";

function YourDebtOff() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="76"
            height="75"
            fill="none"
            viewBox="0 0 76 75"
        >
            <g clipPath="url(#clip0_0_89)">
                <path
                    fill="#D9EDFD"
                    d="M55 41a8 8 0 100-16 8 8 0 000 16z"
                ></path>
            </g>
            <g clipPath="url(#clip1_0_89)">
                <path
                    fill="#D9EDFD"
                    d="M36 48a8 8 0 100-16 8 8 0 000 16z"
                ></path>
            </g>
            <path
                fill="#000"
                d="M37.978 45.115c.648 0 1.172-.524 1.172-1.172v-.953c.415-.17.784-.434 1.081-.77.511-.59.79-1.345.783-2.125a3.003 3.003 0 00-3.117-3.092h-.803a.727.727 0 01-.767-.792.817.817 0 01.183-.543.739.739 0 01.584-.21h2.25a1.172 1.172 0 000-2.343h-.194v-.89a1.172 1.172 0 00-2.343 0v.904a2.994 2.994 0 00-2.824 3.081 3.06 3.06 0 003.111 3.137h.803a.688.688 0 01.773.748.881.881 0 01-.198.577.72.72 0 01-.575.219h-2.25a1.172 1.172 0 000 2.344h1.16v.709c0 .647.524 1.171 1.171 1.171zM41.762 46.43c.325.14.638.277.927.406.641.287 1.214.541 1.707.702a11.855 11.855 0 003.065-3.314 14.17 14.17 0 01-1.802-1.542 9.448 9.448 0 01-3.897 3.747z"
            ></path>
            <path
                fill="#000"
                d="M30.85 44.684a9.37 9.37 0 0111.073-14.86c.168-.773.402-1.53.698-2.262a11.694 11.694 0 00-14.485 17.54c.9-.173 1.812-.317 2.713-.418zM73.837 48.01c-.61-.633-1.978-1.592-4.304-.9-1.395.415-5.837 3.564-9.512 6.282.268.872.371 1.787.304 2.698 4.289-3.195 8.853-6.43 9.876-6.733a1.902 1.902 0 011.946.277c.533.685.665 1.6.348 2.407-.832 2.448-16.39 15.333-22.804 18.887-5.594 3.1-9.278 1.345-11.967.064a65.135 65.135 0 00-14.288-4.656v.458a7.008 7.008 0 01-.262 1.871 64.05 64.05 0 0113.542 4.442A15.61 15.61 0 0043.683 75a14.595 14.595 0 007.145-2.022c5.615-3.112 22.625-16.475 23.886-20.181a4.742 4.742 0 00-.878-4.788z"
            ></path>
            <path
                fill="#000"
                d="M34.323 49.258c2.246.223 4.432.852 6.453 1.856.97.477 1.988.852 3.036 1.119 1.471.252 2.962.373 4.455.363 2.768.074 5.906.16 6.92 1.467.465.65.6 1.481.362 2.245-.28.89-1.07 1.56-2.283 1.938-3.116.97-11.641 1.866-15.167 1.383a1.172 1.172 0 10-.318 2.322c.928.112 1.863.162 2.798.151 4.37 0 10.663-.77 13.385-1.619 1.95-.608 3.308-1.841 3.821-3.472a4.93 4.93 0 00-.745-4.384c-1.694-2.185-5.26-2.281-8.71-2.374a23.975 23.975 0 01-4.016-.309 13.866 13.866 0 01-2.581-.97 20.935 20.935 0 00-7.196-2.05c-3.549-.326-8.548.975-11.64 1.935.302.731.478 1.508.52 2.297 2.903-.91 7.702-2.193 10.907-1.898zM16.406 46.873H1.172a1.171 1.171 0 100 2.343h9.374v19.92H1.172a1.172 1.172 0 000 2.344h15.234a4.691 4.691 0 004.688-4.687V51.56a4.691 4.691 0 00-4.688-4.687zm2.344 19.92a2.346 2.346 0 01-2.344 2.343h-3.515v-19.92h3.515a2.347 2.347 0 012.344 2.343v15.234zM55.662 44.529A11.718 11.718 0 1043.944 32.81 11.733 11.733 0 0055.662 44.53zm0-21.093a9.375 9.375 0 11-9.375 9.375 9.385 9.385 0 019.375-9.375z"
            ></path>
            <path
                fill="#000"
                d="M55.258 34.074h.802a.686.686 0 01.774.747.877.877 0 01-.198.577.72.72 0 01-.576.22h-2.249a1.172 1.172 0 000 2.343h1.16v.71a1.172 1.172 0 002.343 0v-.954a2.93 2.93 0 001.08-.77c.512-.59.79-1.345.783-2.126a3.002 3.002 0 00-3.117-3.09h-.802a.727.727 0 01-.768-.794.819.819 0 01.183-.542.74.74 0 01.585-.21h2.249a1.171 1.171 0 100-2.343h-.193v-.89a1.172 1.172 0 00-2.344 0v.904a2.996 2.996 0 00-2.823 3.081 3.06 3.06 0 003.11 3.137zM55.662 18.749c.647 0 1.172-.525 1.172-1.172V7.03a1.172 1.172 0 10-2.344 0v10.547c0 .647.525 1.172 1.172 1.172zM55.662 3.516c.647 0 1.172-.525 1.172-1.172V1.172a1.171 1.171 0 10-2.344 0v1.172c0 .647.525 1.172 1.172 1.172zM63.865 20.507c.648 0 1.172-.524 1.172-1.172V8.79a1.172 1.172 0 00-2.343 0v10.546c0 .648.524 1.172 1.171 1.172zM63.865 5.273c.648 0 1.172-.524 1.172-1.171V2.93a1.172 1.172 0 00-2.343 0v1.172c0 .647.524 1.17 1.171 1.17zM47.46 20.507c.646 0 1.171-.524 1.171-1.172V8.79a1.172 1.172 0 00-2.344 0v10.546c0 .648.525 1.172 1.172 1.172zM47.46 5.273c.646 0 1.171-.524 1.171-1.171V2.93a1.172 1.172 0 00-2.344 0v1.172c0 .647.525 1.17 1.172 1.17z"
            ></path>
            <defs>
                <clipPath id="clip0_0_89">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(47 25)"
                    ></path>
                </clipPath>
                <clipPath id="clip1_0_89">
                    <path
                        fill="#fff"
                        d="M0 0H16V16H0z"
                        transform="translate(28 32)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default YourDebtOff;
